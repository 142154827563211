import '../styles/login.scss';
import '../styles/button.scss';

import { useAuth0 } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect,useState } from 'react';
import Swal from 'sweetalert2';

import  bottomImage from '../assets/images/bottom-images.svg';

const CssTextField = styled( TextField )( {

  '& label':{
    top:'-8px !important'
  },
  '& MuiInputLabel-sizeSmall':{
    top:'0px !important'
  },
  '& label.MuiInputLabel-shrink':{
    top:'0px !important'
  },
  '& label.Mui-focused': {
    color: '#646464',
    top:'0px !important'

  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#DAE7EC'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#DAE7EC'
    },
    '&:hover fieldset': {
      borderColor: '#DAE7EC'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#DAE7EC'
    }
  }
} );

const minLength = 2;
const alphaExp = /^[a-zA-Z]+$/;
const emailRegex = new RegExp( '[a-z0-9]+@[a-z]+\.[a-z]{2,3}' );
const phoneRegex = new RegExp( '^[0-9]{10}$' );
const phoneLength = 10;

function SignUp( { location } ) {

  const { loginWithRedirect } = useAuth0();
  const [email, setEmail] = useState( '' );
  const [password, setPassword] = useState( '' );
  const [validatePassword, setValidatePassword] = useState( '' );
  const [company, setCompany] = useState( '' );
  const [phoneNo, setPhoneNo] = useState( '' );
  const [formattedPhone, setformattedPhone] = useState( '' );
  const [firstName, setFirstName] = useState( '' );
  const [lastName, setLastName] = useState( '' );
  const [validFirstName, setvalidFirstName] = useState( true );
  const [validLastName, setvalidLastName] = useState( true );
  const [validCompany, setvalidCompany] = useState( true );
  const [validEmail, setvalidEmail] = useState( true );
  const [validPhone, setvalidPhone] = useState( true );

  function signUp(){
    if( validatePassword !== password ){
      Swal.fire(  { title : 'Error', text: 'Both password should match'  } );
    }
    validateFirstName( { target:{ value:firstName } } );
    validateLastName( { target:{ value:lastName } }  );
    validateCompany( { target:{ value:company } } );
    validateEmail( { target:{ value:email } }  );
    validatePhone( { target:{ value:phoneNo } } );

    const validFields = validateFirstName( { target:{ value:firstName } } ) && validateLastName( { target:{ value:lastName } }  ) && validateCompany( { target:{ value:company } } ) && validateEmail( { target:{ value:email } }  ) && validatePhone( { target:{ value:phoneNo } } );

    if( validFields )
    {
      const agent =  {
        agent_name: `${firstName} ${lastName}`,
        phoneNo,
        //agency,
        //lead_source,
        agent_email: email,
        password,
        company_name: company
      //partner,
      //partner_logo
      };

      fetch( 'API url', {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify( agent )
      } );
    }
    else{
      console.log( 'enter proper values' );
    }

  }

  function paramsToObject( entries ) {
    const result = {};
    for( const [key, value] of entries ) {
      result[key] = value;
    }
    return result;
  }

  function validateFirstName( e )
  {

    if( e.target.value.length >= minLength && e.target.value.match( alphaExp ) ){
      setvalidFirstName( true );
    }else{
      setvalidFirstName( false );
    }
    setFirstName( e.target.value );
    return validFirstName;
  }

  function validateLastName( e )
  {
    if( e.target.value.length >= minLength && e.target.value.match( alphaExp ) ){
      setvalidLastName( true );
    }else{
      setvalidLastName( false );
    }
    setLastName( e.target.value );
    return validLastName;
  }

  function validateCompany( e )
  {
    const min = 4;
    if( e.target.value.length >= min && e.target.value.match( alphaExp ) ){
      setvalidCompany( true );
    }else{
      setvalidCompany( false );
    }
    setCompany( e.target.value );
    return validCompany;
  }

  function validateEmail( e )
  {

    if( e.target.value.match( emailRegex ) ){
      setvalidEmail( true );
    }else{
      setvalidEmail( false );
    }
    setEmail( e.target.value );
    return validEmail;
  }

  function validatePhone( e )
  {

    const finalPhone = phonelength( e.target.value );
    if( finalPhone.length == phoneLength && finalPhone.match( phoneRegex ) ){
      setvalidPhone( true );
    }else{
      setvalidPhone( false );
    }
    setPhoneNo( finalPhone );
    return validPhone;
  }

  function phonelength( e ) {
    let count = 0;
    let phone;
    phone = e.toString();
    phone = phone.replace( /^0+/, '' );
    phone = phone.replace( '+1', '' ).replace( '(', '' ).replace( ')', '' );
    const phonestring = phone.toString().slice( 0, phone.length );
    phone = phonestring.replace( /(\d{3})-?/g, function ( m, a ) {
      return ++count <= 2 ? a + '-' : m;
    } );
    phone = phone.slice( 0, 13 );
    // this.validateForm();
    let phoneNumber = phone;

    const j = phoneNumber.replace( /-/g, '' );
    var K = j.replace( /[{()}]/g, '' );
    K = K.replace( / /g, '' );
    const L = K.replace( '+1', '' );
    const A = L.substring( 0, 3 );
    const B = L.substring( 3, 6 );
    const C = L.substring( 6, 10 );
    if ( A != '' && K.length <= 3 ) {
      if ( A.length == 3 ) {
        phoneNumber = '+1(' + A + ') ';
      } else {
        phoneNumber = '+1(' + A;
      }
    } else if ( B != '' && K.length > 3 && K.length < 7 ) {
      phoneNumber = '+1(' + A + ') ' + B;
    } else if ( B != '' && K.length >= 7 ) {
      phoneNumber = '+1(' + A + ') ' + B + '-' + C;
    } else {
      if ( L == '' ) {
        phoneNumber = '';
      }

    }
    setformattedPhone( phoneNumber );
    phone = phoneNumber.replace( '+1', '' ).replace( '(', '' ).replace( ')', '' ).replace( '-', '' ).replace( ' ', '' );
    return phone;
  }


  useEffect( () => {
    const urlParams = new URLSearchParams( location.search );
    const entries = urlParams.entries();
    const params = paramsToObject( entries );

    console.log( params.email );
    setEmail( params.email || '' );
  }, [location] );

  return (

    <Grid className="yellow-bg">
      <Box
        className="auth-box"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <h1 className="login">Agent SignUp</h1>

        <h3 className="login-line">
          The information below is to be filled by <span>agents</span>
        </h3>

        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-around'
          }}
        >
          <Button className="button-auth active" variant="round"   >
            Sign-up
          </Button>

          <Button onClick={() => loginWithRedirect()} className="button-auth" variant="round" >
            Login
          </Button>
        </Box>

        <Box
          component="form"
          sx={{
            padding:{ lg:'0px 15px 0px'  }
          }}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={1} sx={{ margin:{ sm:'10px 0px 0px' } }} >
            <Grid item xs={0.2} ></Grid>

            <Grid container spacing={1} item xs={11.6} lg={12}>
              <Grid item xs={12} lg={6}>
                <CssTextField sx={{ width:'170px' }}
                  className="input-auth"
                  id="outlined-first-name"
                  value={firstName}
                  helperText={validFirstName  ?  '' : 'Fisrt Name is invalid'}
                  error={!validFirstName}
                  onChange={validateFirstName}
                  label={
                    <>
                      First Name <sup>*</sup>
                    </>
                  }
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <CssTextField
                  className="input-auth"
                  id="outlined-basic"
                  helperText={validLastName  ?  '' : 'Last Name is invalid'}
                  error={!validLastName }
                  onChange={validateLastName }
                  value={lastName}
                  label={
                    <>
                      Last Name <sup>*</sup>
                    </>
                  }
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} lg={6}>

                <CssTextField
                  className="input-auth"
                  id="outlined-phone-no"
                  helperText={!validPhone ?  'Phone No is invalid' : ''}
                  error={!validPhone}
                  onChange={validatePhone }
                  value={formattedPhone}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  label={
                    <>
                      Phone no <sup>*</sup>
                    </>
                  }
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <CssTextField
                  className="input-auth"
                  id="outlined-company-name"
                  helperText={validCompany  ?  '' : 'Company Name is invalid'}
                  error={!validCompany }
                  onChange={validateCompany}
                  value={company}
                  label={
                    <>
                      Company Name <sup>*</sup>
                    </>
                  }
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <CssTextField
                  sx={{  margin: '0px' }}
                  fullWidth
                  className="input-auth"
                  id="outlined-email"
                  value={email}
                  helperText={validEmail  ?  '' : 'Email is invalid'}
                  error={!validEmail }
                  onChange={validateEmail}
                  label={
                    <>
                      E-mail <sup>*</sup>
                    </>
                  }
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <CssTextField
                  className="input-auth"
                  id="outlined-password"
                  type="password"
                  value={password}
                  autocomplete="new-password"
                  onChange={e => setPassword( e.target.value )}
                  label={
                    <>
                      Set Password <sup>*</sup>
                    </>
                  }
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <CssTextField
                  autocomplete="new-password"
                  className="input-auth"
                  id="outlined-basic"
                  onChange={e => setValidatePassword( e.target.value )}
                  label={
                    <>
                      Confirm Password <sup>*</sup>
                    </>
                  }
                  variant="outlined"
                />
              </Grid>

              {/* <Grid  item xs={12} lg={12}>
                <p style={{ textAlign:'center',margin:'8px 0px', fontStyle:'italic', fontSize:'12px' }}>OR</p>
              </Grid>

              <Grid  item xs={12} lg={12}>
                <Button onClick={() => loginWithRedirect( { action: 'signup' } )}  variant="action" className="signup-google">
                  <img src={google} alt="google" />&nbsp; Sign Up with Google
                </Button>
              </Grid> */}

              <Grid  item xs={12} lg={12}>
                <p className='help-text'>non id maximus vitae ullamcorper vel enim. eu odio ex maximus In Ut amet, laoreet diam orci sit commodo non vitae commodo quis cursus non. Ut volutpat tincidunt sit leo. Nullam non lacus maximu.</p>
              </Grid>


            </Grid>

            <Grid item xs={0.2} ></Grid>


          </Grid>
        </Box>

        <Button type='button' variant="action" onClick={signUp} disable={!validFirstName || validLastName || validCompany || validEmail} className="signup-submit">
          Submit
        </Button>


      </Box>

      <Grid container spacing={1}>
        <Grid item  direction="row"
          justifyContent="center"
          alignItems="center" >
          <img src={bottomImage} alt="bottomImage"  className="homes"/>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SignUp;
